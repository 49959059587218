<template>
  <section class="ucrs-footerBottom">
    <div class="border-b border-solid border-gray-light py-8">
      <div class="container">
        <div class="ucrs-footerBottom__disclaimer">
          <p>
            UniCredit RE Services S.p.A. Società con socio unico - Capitale
            Sociale € 500.000,00 Interamente versato Iscrizione al Registro
            delle Imprese di Milano, Codice Fiscale e P.IVA n°08583660967
            <br aria-hidden="true" />Numero REA MI-2035532 Società soggetta a
            direzione e coordinamento da parte di UniCredit S.p.A. UniCredit RE
            Services S.p.A. <br aria-hidden="true" />Sede Legale:
            {{ $config.brandAddress.full }} - Numero Verde 800.89.69.68 |
            www.unicreditres.it
          </p>
        </div>
      </div>
    </div>
    <div
      class="py-8"
      :class="{
        'pb-36 lg:pb-8': footerBottomSpaced,
      }"
    >
      <div class="container">
        <div class="ucrs-footerBottom__copyright">
          <div class="flex justify-center pb-4">
            <Logo
              preload
              :type="$options.LogoTypes.White"
              :size="$options.LogoSizes.Small"
            />
          </div>
          <p>
            © 2014 - {{ new Date().getFullYear() }} UniCredit RE Services -
            Tutti i diritti riservati - P.IVA n°08583660967
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

// Static assets
import LogoSizes from '~/assets/js/logo/sizes'
import LogoTypes from '~/assets/js/logo/types'

// Components
import Logo from '~/components/Logo.vue'

export default {
  components: {
    Logo,
  },
  computed: {
    ...mapState(['footerBottomSpaced']),
  },

  LogoTypes,
  LogoSizes,
}
</script>
