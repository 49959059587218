<template>
  <section class="ucrs-footerTop">
    <div class="container">
      <div class="ucrs-footerTop__inner">
        <div class="ucrs-footerTop__item">
          <SvgIcon
            aria-hidden="true"
            name="unicredit-white"
            class="ucrs-footerTop__ico"
          />
          <a
            href="https://www.unicredit.it/it/privati.html?ucid=ILC-IR5859&amp;ccode=ILC-IR5859"
            rel="noopener"
            class="ucrs-footerTop__item-text"
            target="_blank"
            >UniCredit.it</a
          >
        </div>
        <div class="ucrs-footerTop__item">
          <SvgIcon
            aria-hidden="true"
            name="unicreditgroup-white"
            class="ucrs-footerTop__ico"
          />
          <a
            href="https://www.unicreditgroup.eu/it.html?ucid=ILC-IG1063"
            rel="noopener"
            class="ucrs-footerTop__item-text"
            target="_blank"
            >UniCredit Group</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>
