<template>
  <a
    href="#"
    class="ucrs-auth__login"
    aria-label="Login menu"
    @click.prevent="clickLogin"
  >
    <span class="ucrs-auth__loginIconWrapper">
      <SvgIcon name="login-new" class="ucrs-auth__loginIcon" />
    </span>
    <span class="ucrs-auth__loginLabel">
      <span class="hidden lg:inline pt-2">Login</span>
    </span>
  </a>
</template>

<script>
// Mixins
import Auth from '~/mixins/auth'

export default {
  mixins: [Auth],
  methods: {
    clickLogin() {
      this.openLoginPopup({
        events: {
          success: () => {
            this.$router.push('/area-riservata/dashboard')
          },
        },
      })
    },
  },
}
</script>
