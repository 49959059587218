<template>
  <div>
    <div
      ref="accessibilityTab"
      class="z-50 absolute -top-100 flex space-x-4 mx-6 md:mx-16"
    >
      <p class="mt-2 p-6">Passa a</p>
      <a
        class="block mt-2 p-6 bg-white text-blue outline-none focus:ring-2 ring-offset-2 ring-blue"
        href="#main-content"
        @focus="displayAccessibilityTab"
        @blur="hideAccessibilityTab"
        >Contenuto di pagina</a
      >
      <a
        class="block mt-2 p-6 bg-white text-blue outline-none focus:ring-2 ring-offset-2 ring-blue"
        href="#footer-content"
        @focus="displayAccessibilityTab"
        @blur="hideAccessibilityTab"
        >Piè di pagina</a
      >
    </div>
    <header class="ucrs-header">
      <div class="w-full mx-6 md:mx-16">
        <div class="ucrs-header__end border-b-1 border-gray-lighter">
          <NavBar />
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import NavBar from '~/components/NavBar.vue'
export default {
  components: {
    NavBar,
  },
  methods: {
    displayAccessibilityTab() {
      this.$refs.accessibilityTab.classList.remove('absolute', '-top-100')
      document.querySelector('header').style.setProperty('top', '8rem')
    },
    hideAccessibilityTab() {
      this.$refs.accessibilityTab.classList.add('absolute', '-top-100')
      document.querySelector('header').style.removeProperty('top')
    },
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/header.css';
</style>
