<template>
  <OCollapse
    :class="[
      {
        'ucrs-accordion--borderBottomColor': isOpenLocal,
        'ucrs-accordion--noBorderTop': !borderTop,
        'ucrs-accordion--noBorderBottom': !borderBottom,
        'ucrs-accordion--noPadding': !padding,
      },
      classes,
    ]"
    animation="slide"
    :open="isOpenLocal"
    :content-class="contentClass"
    @update:open="isOpenLocal = $event"
  >
    <template #trigger="{ open }">
      <div v-if="$slots.trigger || $scopedSlots.trigger">
        <slot name="trigger" :accordion-id="`${_uid}`" />
      </div>
      <div
        v-else
        :class="[
          'ucrs-accordion__header',
          {
            'hover:text-white': inFooter,
            'hover:text-gray': !inFooter,
          },
        ]"
        role="button"
        tabindex="0"
        :aria-labelledby="`Accordion-${_uid}`"
        :aria-expanded="`${isOpenLocal}`"
        :aria-controls="`AccordionContent-${_uid}`"
        @keydown.space.prevent="() => {}"
        @keyup.space="isOpenLocal = !isOpenLocal"
        @keyup.enter="isOpenLocal = !isOpenLocal"
      >
        <p
          :id="`Accordion-${_uid}`"
          class="font-bold text-lg"
          :class="open ? `ucrs-accordion__text--${highlightedText}` : ''"
        >
          {{ heading }}
        </p>
        <span class="flex h-10 items-center">
          <SvgIcon
            :name="open ? iconOpen : iconClose"
            class="ucrs-accordion__toggle-icon"
          />
        </span>
      </div>
    </template>
    <div
      :id="`AccordionContent-${_uid}`"
      class="ucrs-accordion__content"
      role="region"
      :aria-labelledby="`Accordion-${_uid}`"
    >
      <slot />
    </div>
  </OCollapse>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: null,
    },

    classes: {
      type: String,
      default: null,
    },

    contentClass: {
      type: String,
      default: 'max-h-120',
    },

    iconOpen: {
      type: String,
      default: 'angle-up-blue',
    },

    iconClose: {
      type: String,
      default: 'angle-down',
    },

    highlightedText: {
      type: String,
      default: 'blue',
    },

    inFooter: {
      type: Boolean,
      default: false,
    },

    isOpen: {
      type: Boolean,
      default: false,
    },

    borderTop: {
      type: Boolean,
      default: true,
    },

    borderBottom: {
      type: Boolean,
      default: true,
    },

    padding: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isOpenLocal: this.isOpen,
    }
  },

  watch: {
    isOpen(isOpenValue) {
      this.isOpenLocal = isOpenValue
    },

    isOpenLocal(newLocal, oldLocal) {
      if (newLocal === oldLocal) return

      this.$emit('update', newLocal)
    },
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/accordion';
</style>
