<template>
  <footer id="footer-content">
    <FooterTop />
    <FooterNav />
    <FooterBottom />
  </footer>
</template>

<script>
import FooterTop from '~/components/FooterTop.vue'
import FooterNav from '~/components/FooterNav.vue'
import FooterBottom from '~/components/FooterBottom.vue'

export default {
  components: {
    FooterTop,
    FooterNav,
    FooterBottom,
  },
}
</script>
<style lang="postcss">
@import '~assets/css/components/footer';
</style>
