<template>
  <section class="ucrs-footerNav">
    <div
      class="border-b border-solid border-gray-light md:border-none pt-8 pb-12 md:py-9"
    >
      <div class="container">
        <ul class="ucrs-footerNav__info">
          <li
            v-for="(info, i) in infoNav"
            :key="i"
            class="ucrs-footerNav__info-item"
          >
            <a
              :href="info.href"
              :target="info.target ? info.target : null"
              v-text="info.text"
            />
            <span v-if="info.cookieLabel" v-text="cookieLabel" />
          </li>
        </ul>
      </div>
    </div>
    <div class="border-b border-solid border-gray-light py-12">
      <div class="container">
        <div class="ucrs-footerNav__row">
          <template v-for="(item, index) in items">
            <p
              :key="`FooterNavItem_${index}`"
              v-dompurify-html="item.text"
              class="ucrs-footerNav__title hidden md:block"
            ></p>
            <ul
              :key="index"
              class="ucrs-footerNav__list last:pb-0 hidden md:block"
            >
              <template v-for="(subItem, i) in item.children">
                <li v-if="!subItem.onlyMobile" :key="i" class="pb-2">
                  <a
                    :href="subItem.href"
                    :target="subItem.target ? subItem.target : null"
                    v-text="subItem.text"
                  ></a>
                </li>
              </template>
            </ul>
            <Accordion
              :key="item.text"
              class="ucrs-footerNav__accordion block md:hidden"
              :heading="item.textMobile"
              icon-open="angle-up-white"
              icon-close="angle-down-white"
              highlighted-text="white"
              :in-footer="true"
            >
              <ul :key="index" class="ucrs-footerNav__list last:pb-0">
                <template v-for="(subItem, i) in item.children">
                  <li v-if="!subItem.onlyMobile" :key="i" class="pb-2">
                    <a
                      :href="subItem.href"
                      :target="subItem.target ? subItem.target : null"
                      v-text="subItem.text"
                    ></a>
                  </li>
                </template>
              </ul>
            </Accordion>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Libs
import { mapGetters } from 'vuex'

// Static assets
import { CookieAcceptValue, CookieRejectValue } from '~/assets/js/cookie'

// Components
import Accordion from '~/components/Accordion.vue'

export default {
  components: {
    Accordion,
  },
  computed: {
    ...mapGetters({
      cookie: 'cookie/getCookie',
    }),

    cookieLabel() {
      return this.cookie === CookieAcceptValue
        ? ': Tutti attivi'
        : this.cookie === CookieRejectValue
        ? ': Tutti disattivi'
        : ''
    },

    items() {
      return [
        this.$utilities.navigation.chisiamoFooter,
        this.$utilities.navigation.vendo,
        this.$utilities.navigation.cerco,
        this.$utilities.navigation.serviziDocumenti,
        this.$utilities.navigation.news,
      ]
    },

    infoNav() {
      const infoNav = []
      infoNav.push(
        this.$utilities.navigation.cartadeiservizi,
        this.$utilities.navigation.privacy,
        this.$utilities.navigation.datisocietari,
        this.$utilities.navigation.disclaimer
      )
      if (!this.$store.state.auth.loggedIn) {
        infoNav.push(
          this.$utilities.navigation.informativacookies,
          this.$utilities.navigation.faiLeTueScelteCookie
        )
      }
      infoNav.push(this.$utilities.navigation.modelloorganizzazione)
      if (this.$config.hasAccessibilityLink)
        infoNav.push(this.$utilities.navigation.accessibilita)
      return infoNav
    },
  },
}
</script>
